<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <el-tooltip
        slot="button"
        class="item"
        effect="dark"
        content="Create a tag"
        placement="top"
      >
        <el-button type="success" size="mini" @click="createTag">
          <i class="fa fa-plus"></i>
        </el-button>
      </el-tooltip>
    </Heading>
    <el-dialog
      title="Tag"
      :visible.sync="showCreateTag"
      width="50%"
      @close="getTags()"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="Type">
          <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="AI Prompt">
          <el-input type="textarea" :rows="8" v-model="form.prompt"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCreateTag = false">
          {{ $t("alert.cancel") }}
        </el-button>
        <el-button type="success" @click="saveTag">
          {{ $t("alert.save") }}
        </el-button>
      </span>
    </el-dialog>
    <hr />
    <div
      v-for="(tagsArr, key, index) in tag_analysis_arr"
      :key="key"
      class="mb-4"
    >
      <el-table :data="tagsArr" border stripe>
        <el-table-column prop="name" :label="key"> </el-table-column>
        <el-table-column prop="prompt" label="AI Prompt"> </el-table-column>
        <el-table-column prop="action" label="Actions" width="200px">
          <template slot-scope="scope">
            <el-tooltip content="Delete" placement="top">
              <el-button
                plain
                type="danger"
                icon="el-icon-delete-solid"
                size="mini"
                @click.stop="deleteTag(scope.row.id)"
              />
            </el-tooltip>
            <el-tooltip content="Edit" placement="top">
              <el-button
                @click="editTag(key, scope.$index)"
                plain
                type="success"
                icon="el-icon-edit-outline"
                size="mini"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";

export default {
  metaInfo() {
    return {
      title: "Question Tags - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [],

  props: [],
  data() {
    return {
      tags: [],
      form: {
        id: null,
        type: "",
        name: ""
      },
      showCreateTag: false
    };
  },
  computed: {
    tag_analysis_arr() {
      let res = null;
      if (this.tags) {
        res = _.groupBy(this.tags, "type");
      }
      return res;
    },
    myTitle() {
      return this.$t("sat.Question Labels");
    }
  },
  watch: {},

  mounted() {
    this.getTags();
  },

  methods: {
    createTag() {
      this.form = {
        id: null,
        type: "",
        name: ""
      };
      this.showCreateTag = true;
    },
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    async saveTag() {
      if (this.form.id && this.form.id > 0) {
        await SAT.updateSATQuestionTags(this.form.id, {
          type: this.form.type,
          name: this.form.name,
          prompt: this.form.prompt
        });
      } else {
        await SAT.createSATQuestionTags({
          type: this.form.type,
          name: this.form.name,
          prompt: this.form.prompt
        });
      }
      this.$message({
        message: "Success!",
        type: "success"
      });
      this.showCreateTag = false;
      this.getTags();
    },
    async deleteTag(id) {
      await SAT.deleteSATQuestionTags(id);
      this.$message({
        message: "Deleted!",
        type: "success"
      });
      this.getTags();
    },
    editTag(key, index) {
      this.showCreateTag = true;
      this.form = this.tag_analysis_arr[key][index];
    }
  }
};
</script>

<style scoped></style>
